import { set } from "../lib/storage";

export const setUtmAndReferrer = (queryParam) => {
  const urlParams = new URLSearchParams(queryParam);
  const referrer = document.referrer;
  const prevReferrerInfo =
    JSON.parse(localStorage.getItem("referralInfo")) || {};
  const referralInfo = { ...prevReferrerInfo };

  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  const utmCampaign = urlParams.get("utm_campaign");

  if (utmSource) referralInfo.utm_source = utmSource;
  if (utmMedium) referralInfo.utm_medium = utmMedium;
  if (utmCampaign) referralInfo.utm_campaign = utmCampaign;
  if (referrer !== "") referralInfo.referrer = referrer;

  set("referralInfo", JSON.stringify(referralInfo));
};
