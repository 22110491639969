exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-features-index-jsx": () => import("./../../../src/pages/features/index.jsx" /* webpackChunkName: "component---src-pages-features-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-solutions-hyperlocal-index-jsx": () => import("./../../../src/pages/solutions/hyperlocal/index.jsx" /* webpackChunkName: "component---src-pages-solutions-hyperlocal-index-jsx" */),
  "component---src-pages-solutions-ondc-index-jsx": () => import("./../../../src/pages/solutions/ondc/index.jsx" /* webpackChunkName: "component---src-pages-solutions-ondc-index-jsx" */),
  "component---src-pages-terms-condition-index-jsx": () => import("./../../../src/pages/terms-condition/index.jsx" /* webpackChunkName: "component---src-pages-terms-condition-index-jsx" */),
  "component---src-pages-whyus-index-jsx": () => import("./../../../src/pages/whyus/index.jsx" /* webpackChunkName: "component---src-pages-whyus-index-jsx" */),
  "component---src-pages-whyus-pricing-svg-jsx": () => import("./../../../src/pages/whyus/PricingSvg.jsx" /* webpackChunkName: "component---src-pages-whyus-pricing-svg-jsx" */)
}

